/* eslint-disable no-console */
$(document).ready(function () {

  //handle UL>LI>A clicks for country selection
  $('.countryLink').click(function (e) {
    var el = e.target;
    var img = el.querySelector('img');
    var countryId = el.dataset.countryid;
    var countryName = img.alt;
    var countryText = document.querySelector('#countryText');
    var urlParams = new URLSearchParams(document.location.search);
    // clear prior switch data since not needed
    urlParams.delete('d'); urlParams.delete('c');
    // if clicked is already selected
    if (countryName == countryText.textContent) {
      // we unset the country filter => all countries
      urlParams.set('c','');
    } else {
      urlParams.set('c',countryId);
    }
    document.location.search = '?'+urlParams.toString();
    e.preventDefault();
  });


  // division selection
  $('.division-select a').click(function (e) {
    var el = e.target, eli;
    if (el.tagName.toLowerCase() === 'i') {
      eli = el;
      el = el.closest('a');
    } else {
      eli = el.querySelector('i');
    }
    var urlParams = new URLSearchParams(document.location.search);
    // clear prior switch data since not needed
    urlParams.delete('d'); urlParams.delete('c');
    // if clicked is already checked
    if (eli.classList.contains('fa-check-circle')) {
      // we unset the division filter -> all divisions
      urlParams.set('d','');
    } else {
      var divisionValue = el.dataset.divisionid;
      urlParams.set('d',divisionValue);
    }
    document.location.search = '?'+urlParams.toString();
    e.preventDefault();
  });

});
